@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Rule of 56 Styles */

@keyframes RingProgress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.ActivityRings {
  height: 100%;
  width: 100%;
}

.ActivityRings .ring {
  transform-origin: 50%;
}

.ActivityRings .completed {
  animation: RingProgress 1s ease-in-out forwards;
  stroke-linecap: round;
}

.ActivityRings circle {
  fill: none;
}

.ring1 .background {
  stroke: var(--theme4);
  opacity: 0.09;
}

.ring1 .completed {
  stroke: var(--theme4);
}

.ring2 .background {
  stroke: var(--theme2);
  opacity: 0.09;
}

.ring2 .completed {
  stroke: var(--theme2);
}

.ring3 .background {
  stroke: var(--theme5);
  opacity: 0.09;
}

.ring3 .completed {
  stroke: var(--theme5);

}
.ring4 .background {
  stroke: var(--theme3);
  opacity: 0.09;
}

.ring4 .completed {
  stroke: var(--theme3);

}

.ql-editor{
  min-height:200px;
}